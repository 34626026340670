import images from 'media/images/models';
import { collectionIds } from 'constants/ids';

export const modelImages: IModelImage[] = [
  {
    collection: collectionIds._1895,
    modelId: 'B4012500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'B4031200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'B4071400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'B4088200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.amour,
    modelId: 'B4093600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.amour,
    modelId: 'B4093900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'B4072000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'B4092800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'B4093000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.broderie,
    modelId: 'N4744900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.broderie,
    modelId: 'B4225600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.cDeCartier,
    modelId: 'B4232500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.cDeCartier,
    modelId: 'B4054000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.cDeCartier,
    modelId: 'B4086400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4127500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'B4077900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'B4221900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'B4050800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'B4083300',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'B4085200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ruban,
    modelId: 'B4099400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.vendome,
    modelId: 'B4209900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4162900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4235100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4743600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4163900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4761400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4759400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4759000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4197900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4725100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4188400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4246000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751700',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'N4744600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4724500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4774200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4777600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4774600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.ruban,
    modelId: 'N4250400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.vendome,
    modelId: 'N4204200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4162900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4235100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4743600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4163900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4761400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4759400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4759000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4197900',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4725100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.ballerine,
    modelId: 'N4188400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4246000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751700',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.destinee,
    modelId: 'N4751000',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'N4744600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4724500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4774200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4777600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4774600',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.love,
    modelId: 'N4774300',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.ruban,
    modelId: 'N4250400',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds.vendome,
    modelId: 'N4204200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4801100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4801100',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },

  {
    collection: collectionIds._1895,
    modelId: 'N4797200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4797200',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },

  {
    collection: collectionIds._1895,
    modelId: 'N4798500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4798500',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },

  {
    collection: collectionIds._1895,
    modelId: 'N4799800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds._1895,
    modelId: 'N4799800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },

  {
    collection: collectionIds.etincelle,
    modelId: 'N4802300',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'N4802300',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },

  {
    collection: collectionIds.etincelle,
    modelId: 'N4803800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'front',
  },
  {
    collection: collectionIds.etincelle,
    modelId: 'N4803800',
    get imageUrl() {
      return images[`${this.modelId}_${this.angle}`];
    },
    angle: 'side',
  },
];
