export const linksFromCSV = {
  'cartier.com': {
    GB: 'https://www.cartier.com/en-gb',
    FR: 'https://www.cartier.com/fr-fr',
    DE: 'https://www.cartier.com/de-de',
    IT: 'https://www.cartier.com/it-it',
    ES: 'https://www.cartier.com/es-es',
    'PT-PT': 'https://www.cartier.com/pt-pt',
    'PT-en': 'https://www.cartier.com/en-pt',
    'NL - NL': 'https://www.cartier.com/nl-nl',
    'EN - NL': 'https://www.cartier.com/en-nl',
    'BE - fr': 'https://www.cartier.com/fr-be',
    'Be - en': 'https://www.cartier.com/en-be',
    'LU - fr': 'https://www.cartier.com/fr-lu',
    'LU - en': 'https://www.cartier.com/en-lu',
    'CH-DE': 'https://www.cartier.com/de-ch',
    'CH-FR': 'https://www.cartier.com/fr-ch',
    'CH-IT': 'https://www.cartier.com/it-ch',
    US: 'https://www.cartier.com/en-us',
    'CA-en': 'https://www.cartier.com/en-ca',
    'CA-fr': 'https://www.cartier.com/fr-ca',
    'AT-de': 'https://www.cartier.com/de-at',
    'AT-en': 'https://www.cartier.com/en-at',
    'MC-En': 'https://www.cartier.com/en-mc',
    'MC-fr': 'https://www.cartier.com/fr-mc',
    IE: 'https://www.cartier.com/en-ie',
    'KO-Kr': 'https://www.cartier.com/ko-kr',
    TW: 'https://www.cartier.com/zh-tw',
    'HK-Zh': 'https://www.cartier.hk/zh-hk',
    'HK-En': 'https://www.cartier.hk/en-hk',
    'SG-Zh': 'https://www.cartier.sg/zh-sg',
    'SG-En': 'https://www.cartier.sg/en-sg',
    AU: 'https://www.cartier.com.au/en-au',
    'SA-En': 'https://www.cartier.com/en-sa',
    'AE-En': 'https://www.cartier.com/en-ae',
    'MY-En': 'https://www.cartier.com/en-my',
    'TH-En': 'https://www.cartier.com/en-th',
    'en-de': 'https://www.cartier.com/en-de/',
    'en-es': 'https://www.cartier.com/en-es/',
    'en-it': 'https://www.cartier.com/en-it/',
    'en-ch': 'https://www.cartier.com/en-ch/',
    'nl-be': 'https://www.cartier.com/nl-be/',
    'sv-se': 'https://www.cartier.com/sv-se/',
    'en-se': 'https://www.cartier.com/en-se/',
    'en-dk': 'https://www.cartier.com/en-dk/',
    'en-gr': 'https://www.cartier.com/en-gr/',
    'en-cz': 'https://www.cartier.com/en-cz/',
  },
  'Store locator': {
    GB: 'https://stores.cartier.com/search?_ga=2.263126021.809248960.1649662198-1642640114.1630325847&_gac=1.226583407.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    FR: 'https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    DE: 'https://stores.cartier.com/de/search?category=storeLocatorSearch',
    IT: 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
    ES: 'https://stores.cartier.com/es/search?category=storeLocatorSearch',
    'PT-PT':
      'https://stores.cartier.com/search?_ga=2.263126021.809248960.1649662198-1642640114.1630325847&_gac=1.226583407.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'PT-en':
      'https://stores.cartier.com/en_pt/search?_ga=2.50779562.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'NL - NL':
      'https://stores.cartier.com/search?_ga=2.263126021.809248960.1649662198-1642640114.1630325847&_gac=1.226583407.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'EN - NL': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'BE - fr':
      'https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'Be - en':
      'https://stores.cartier.com/en_be/search?_ga=2.38213152.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'LU - fr':
      'https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'LU - en':
      'https://stores.cartier.com/en_lu/search?_ga=2.38213152.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'CH-DE': 'https://stores.cartier.com/de/search?category=storeLocatorSearch',
    'CH-FR': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
    'CH-IT': 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
    US: 'https://stores.cartier.com/en_us/search?category=storeLocatorSearch&_ga=2.19909885.472891036.1650380385-584123294.1631796949&_gac=1.222578153.1648841086.CjwKCAjwxZqSBhAHEiwASr9n9DuAux2S5OoMwUXuRwsR_puhmSMJNCq7Oci8ctB6jAWjE-sTjoKGFBoC9QUQAvD_BwE',
    'CA-en':
      'https://stores.cartier.com/en_ca/search?category=storeLocatorSearch&_ga=2.233353054.1525207585.1655745303-1124020227.1652716806',
    'CA-fr':
      'https://stores.cartier.com/fr_ca/search?category=storeLocatorSearch&_ga=2.233353054.1525207585.1655745303-1124020227.1652716806',
    'AT-de': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'AT-en': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'MC-En': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
    'MC-fr': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
    IE: 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'KO-Kr':
      'https://stores.cartier.com/ko/search?category=storeLocatorSearch&_ga=2.129267402.717561068.1655806915-1124020227.1652716806',
    TW: 'https://stores.cartier.com/zh_tw/search?utm_source=Services&utm_medium=email-auto&utm_%5B%E2%80%A6%5Dubscription-TW=&utm_content=Body&category=storeLocatorSearch&_ga=2.167474552.717561068.1655806915-1124020227.1652716806',
    'HK-Zh':
      'https://stores.cartier.com/zh_hk/search?category=storeLocatorSearch&geocoderBias=47.1449%2C8.1551%2C100&q=%E9%A6%99%E6%B8%AF&r=10',
    'HK-En':
      'https://stores.cartier.com/en_hk/search?q=hong+kong&category=storeLocatorSearch&r=10&geocoderBias=47.1449%2C8.1551%2C100',
    'SG-Zh': 'https://stores.cartier.com/zh_sg/search?category=storeLocatorSearch',
    'SG-En': 'https://stores.cartier.com/en_sg/search?category=storeLocatorSearch',
    AU: 'https://stores.cartier.com/en_au/search?category=storeLocatorSearch',
    'SA-En':
      'https://stores.cartier.com/en_sa/search?category=storeLocatorSearch&_ga=2.57166442.1525207585.1655745303-1124020227.1652716806',
    'AE-En':
      'https://stores.cartier.com/en_ae/search?category=storeLocatorSearch&_ga=2.188887405.717561068.1655806915-1124020227.1652716806',
    'MY-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.201488855.717561068.1655806915-1124020227.1652716806',
    'TH-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.134484407.717561068.1655806915-1124020227.1652716806',
    'en-de': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-es': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-it': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-ch': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'nl-be': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'sv-se': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-se': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-dk': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-gr': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-cz': 'https://stores.cartier.com/search?category=storeLocatorSearch',
  },
  'Contact us': {
    GB: 'https://www.cartier.com/en-gb/contact-us',
    FR: 'https://www.cartier.com/fr-fr/contact-us',
    DE: 'https://www.cartier.com/de-de/contact-us',
    IT: 'https://www.cartier.com/it-it/contact-us',
    ES: 'https://www.cartier.com/es-es/contact-us',
    'PT-PT': 'https://www.cartier.com/pt-pt/contact-us',
    'PT-en': 'https://www.cartier.com/en-pt/contact-us',
    'NL - NL': 'https://www.cartier.com/nl-nl/contact-us',
    'EN - NL': 'https://www.cartier.com/en-nl/contact-us',
    'BE - fr': 'https://www.cartier.com/fr-be/contact-us',
    'Be - en': 'https://www.cartier.com/en-be/contact-us',
    'LU - fr': 'https://www.cartier.com/fr-lu/contact-us',
    'LU - en': 'https://www.cartier.com/en-lu/contact-us',
    'CH-DE': 'https://www.cartier.com/de-ch/contact-us',
    'CH-FR': 'https://www.cartier.com/fr-ch/contact-us',
    'CH-IT': 'https://www.cartier.com/it-ch/contact-us',
    US: 'https://www.cartier.com/en-us/contact-customer-care',
    'CA-en': 'https://www.cartier.com/en-ca/contact-customer-care',
    'CA-fr': 'https://www.cartier.com/fr-ca/contact-customer-care',
    'AT-de': 'https://www.cartier.com/de-at/contact-us',
    'AT-en': 'https://www.cartier.com/en-at/contact-us',
    'MC-En': 'https://www.cartier.com/en-mc/contact-us',
    'MC-fr': 'https://www.cartier.com/fr-mc/contact-us',
    IE: 'https://www.cartier.com/en-ie/contact-us',
    'KO-Kr': 'https://www.cartier.com/ko-kr/contact-customer-care',
    TW: 'https://www.cartier.com/zh-tw/contact-customer-care',
    'HK-Zh': 'https://www.cartier.hk/zh-hk/%E8%81%AF%E7%B9%AB%E6%88%91%E5%80%91.html',
    'HK-En': 'https://www.cartier.hk/en-hk/contact-us.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/contact-us.html',
    'SG-En': 'https://www.cartier.sg/en-sg/contact-us.html',
    AU: 'https://www.cartier.com.au/en-au/contact-us.html',
    'SA-En': 'https://www.cartier.com/en-sa/contact-customer-care',
    'AE-En': 'https://www.cartier.com/en-ae/contact-customer-care',
    'MY-En': 'https://www.cartier.com/en-my/contact-customer-care',
    'TH-En': 'https://www.cartier.com/en-th/contact-customer-care',
    'en-de': 'https://www.cartier.com/en-de/contact-us',
    'en-es': 'https://www.cartier.com/en-es/contact-us',
    'en-it': 'https://www.cartier.com/en-it/contact-us',
    'en-ch': 'https://www.cartier.com/en-ch/contact-us',
    'nl-be': 'https://www.cartier.com/nl-be/contact-us',
    'sv-se': 'https://www.cartier.com/sv-se/contact-us',
    'en-se': 'https://www.cartier.com/en-se/contact-us',
    'en-dk': 'https://www.cartier.com/en-dk/contact-us',
    'en-gr': 'https://www.cartier.com/en-gr/contact-us',
    'en-cz': 'https://www.cartier.com/en-cz/contact-us',
  },
  Help: {
    GB: 'https://www.cartier.com/en-gb/services/your-purchase-concierge/faq/orders-payment',
    FR: 'https://www.cartier.com/fr-fr/services/your-purchase-concierge/faq/orders-payment',
    DE: 'https://www.cartier.com/de-de/services/your-purchase-concierge/faq/orders-payment',
    IT: 'https://www.cartier.com/it-it/services/your-purchase-concierge/faq/orders-payment',
    ES: 'https://www.cartier.com/es-es/services/your-purchase-concierge/faq/orders-payment',
    'PT-PT': 'https://www.cartier.com/pt-pt/services/your-purchase-concierge/faq/orders-payment',
    'PT-en': 'https://www.cartier.com/en-pt/services/your-purchase-concierge/faq/orders-payment',
    'NL - NL': 'https://www.cartier.com/nl-nl/services/your-purchase-concierge/faq/orders-payment',
    'EN - NL': 'https://www.cartier.com/en-nl/services/your-purchase-concierge/faq/orders-payment',
    'BE - fr': 'https://www.cartier.com/fr-be/services/your-purchase-concierge/faq/orders-payment',
    'Be - en': 'https://www.cartier.com/en-be/services/your-purchase-concierge/faq/orders-payment',
    'LU - fr': 'https://www.cartier.com/fr-lu/services/your-purchase-concierge/faq/orders-payment',
    'LU - en': 'https://www.cartier.com/en-lu/services/your-purchase-concierge/faq/orders-payment',
    'CH-DE': 'https://www.cartier.com/de-ch/services/your-purchase-concierge/faq/orders-payment',
    'CH-FR': 'https://www.cartier.com/fr-ch/services/your-purchase-concierge/faq/orders-payment',
    'CH-IT': 'https://www.cartier.com/it-ch/services/your-purchase-concierge/faq/orders-payment',
    US: 'https://www.cartier.com/en-us/faq-product.html',
    'CA-en': 'https://www.cartier.com/en-ca/faq-product.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/faq-product.html',
    'AT-de': 'https://www.cartier.com/de-at/services/your-purchase-concierge/faq/orders-payment',
    'AT-en': 'https://www.cartier.com/en-at/services/your-purchase-concierge/faq/orders-payment',
    'MC-En': 'https://www.cartier.com/en-mc/services/your-purchase-concierge/faq/orders-payment',
    'MC-fr': 'https://www.cartier.com/fr-mc/services/your-purchase-concierge/faq/orders-payment',
    IE: 'https://www.cartier.com/en-ie/services/your-purchase-concierge/faq/orders-payment',
    'KO-Kr': 'https://www.cartier.com/ko-kr/faq-product.html',
    TW: 'https://www.cartier.com/zh-tw/faq-product.html',
    'HK-Zh':
      'https://www.cartier.hk/zh-hk/%E6%9C%8D%E5%8B%99/%E8%B3%BC%E7%89%A9%E7%A6%AE%E8%B3%93%E6%9C%8D%E5%8B%99/%E5%B8%B8%E8%A6%8B%E5%95%8F%E9%A1%8C/%E7%B6%B2%E4%B8%8A%E5%8F%8A%E9%9B%BB%E8%A9%B1%E8%A8%82%E8%B3%BC/%E5%A6%82%E4%BD%95%E6%96%BC%E7%B6%B2%E4%B8%8A%E8%A8%82%E8%B3%BC-%E9%A0%811.html',
    'HK-En': 'https://www.cartier.hk/en-hk/services/your-purchase-concierge/faq/orders/how-to-shop-online-page-1.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/services/your-purchase-concierge/faq/orders/how-to-shop-online-page-1.html',
    'SG-En': 'https://www.cartier.sg/en-sg/services/your-purchase-concierge/faq/orders/how-to-shop-online-page-1.html',
    AU: 'https://www.cartier.com.au/en-au/services/your-purchase-concierge/faq/orders/how-to-shop-online-page-1.html',
    'SA-En': 'https://www.cartier.com/en-sa/faq-product.html',
    'AE-En': 'https://www.cartier.com/en-ae/faq-product.html',
    'MY-En': 'https://www.cartier.com/en-my/faq-product.html',
    'TH-En': 'https://www.cartier.com/en-th/faq-product.html',
    'en-de': 'https://www.cartier.com/en-de/services/your-purchase-concierge/faq',
    'en-es': 'https://www.cartier.com/en-es/services/your-purchase-concierge/faq',
    'en-it': 'https://www.cartier.com/en-it/services/your-purchase-concierge/faq',
    'en-ch': 'https://www.cartier.com/en-ch/services/your-purchase-concierge/faq',
    'nl-be': 'https://www.cartier.com/nl-be/services/your-purchase-concierge/faq',
    'sv-se': 'https://www.cartier.com/sv-se/services/your-purchase-concierge/faq',
    'en-se': 'https://www.cartier.com/en-se/services/your-purchase-concierge/faq',
    'en-dk': 'https://www.cartier.com/en-dk/services/your-purchase-concierge/faq',
    'en-gr': 'https://www.cartier.com/en-gr/services/your-purchase-concierge/faq',
    'en-cz': 'https://www.cartier.com/en-cz/services/your-purchase-concierge/faq',
  },
  'Find a boutique': {
    GB: 'https://stores.cartier.com/?_ga=2.25147571.809248960.1649662198-1642640114.1630325847&_gac=1.250685172.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE',
    FR: 'https://stores.cartier.com/?_ga=2.25147571.809248960.1649662198-1642640114.1630325847&_gac=1.250685172.1649142673.CjwKCAjw0a-https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    DE: 'https://stores.cartier.com/de/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    IT: 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
    ES: 'https://stores.cartier.com/es/search?category=storeLocatorSearch',
    'PT-PT':
      'https://stores.cartier.com/search?_ga=2.263126021.809248960.1649662198-1642640114.1630325847&_gac=1.226583407.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'PT-en':
      'https://stores.cartier.com/en_pt/search?_ga=2.50779562.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'NL - NL':
      'https://stores.cartier.com/search?_ga=2.263126021.809248960.1649662198-1642640114.1630325847&_gac=1.226583407.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'EN - NL': 'https://stores.cartier.com/en_nl/search?category=storeLocatorSearch',
    'BE - fr':
      'https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'Be - en':
      'https://stores.cartier.com/en_be/search?_ga=2.38213152.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'LU - fr':
      'https://stores.cartier.com/fr/search?_ga=2.224804892.766479681.1650354733-1642640114.1630325847&_gac=1.195226078.1649142673.CjwKCAjw0a-SBhBkEiwApljU0j2rpE2YaCrfZvEk_DrfJaBSDcbe7t7s8xaH2fUZlrylbKPLRHtUGBoCO8cQAvD_BwE&category=storeLocatorSearch',
    'LU - en':
      'https://stores.cartier.com/en_lu/search?_ga=2.38213152.2056974549.1660679797-335636536.1660679797&category=storeLocatorSearch',
    'CH-DE': 'https://stores.cartier.com/de/search?category=storeLocatorSearch',
    'CH-FR': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
    'CH-IT': 'https://stores.cartier.com/it/search?category=storeLocatorSearch',
    US: 'https://stores.cartier.com/en_us/search?category=storeLocatorSearch&_ga=2.252707276.472891036.1650380385-584123294.1631796949&_gac=1.45704534.1648841086.CjwKCAjwxZqSBhAHEiwASr9n9DuAux2S5OoMwUXuRwsR_puhmSMJNCq7Oci8ctB6jAWjE-sTjoKGFBoC9QUQAvD_BwE',
    'CA-en': '',
    'CA-fr': '',
    'AT-de': 'https://stores.cartier.com/de_at/search?category=storeLocatorSearch',
    'AT-en': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'MC-En': 'https://stores.cartier.com/en_mc/search?category=storeLocatorSearch',
    'MC-fr': 'https://stores.cartier.com/fr/search?category=storeLocatorSearch',
    IE: 'https://stores.cartier.com/en_ie/search?category=storeLocatorSearch',
    'KO-Kr':
      'https://stores.cartier.com/ko/search?category=storeLocatorSearch&_ga=2.167474552.717561068.1655806915-1124020227.1652716806',
    TW: 'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.167007864.717561068.1655806915-1124020227.1652716806',
    'HK-Zh':
      'https://stores.cartier.com/zh_hk/search?category=storeLocatorSearch&geocoderBias=47.1449%2C8.1551%2C100&q=%E9%A6%99%E6%B8%AF&r=10',
    'HK-En':
      'https://stores.cartier.com/en_hk/search?q=hong+kong&category=storeLocatorSearch&r=10&geocoderBias=47.1449%2C8.1551%2C100',
    'SG-Zh':
      'https://www.cartier.sg/zh-sg/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC.html?_ga=2.65041262.1113221344.1655818093-1124020227.1652716806&cc=SG',
    'SG-En': 'https://stores.cartier.com/en_sg/search?category=storeLocatorSearch',
    AU: 'https://stores.cartier.com/en_au/search?category=storeLocatorSearch',
    'SA-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.57255530.1525207585.1655745303-1124020227.1652716806',
    'AE-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.201488855.717561068.1655806915-1124020227.1652716806',
    'MY-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.155530749.717561068.1655806915-1124020227.1652716806',
    'TH-En':
      'https://stores.cartier.com/search?category=storeLocatorSearch&_ga=2.134484407.717561068.1655806915-1124020227.1652716806',
    'en-de': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-es': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-it': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-ch': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'nl-be': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'sv-se': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-se': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-dk': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-gr': 'https://stores.cartier.com/search?category=storeLocatorSearch',
    'en-cz': 'https://stores.cartier.com/search?category=storeLocatorSearch',
  },
  Services: {
    GB: 'https://www.cartier.com/en-gb/services',
    FR: 'https://www.cartier.com/fr-fr/services',
    DE: 'https://www.cartier.com/de-de/services',
    IT: 'https://www.cartier.com/it-it/services',
    ES: 'https://www.cartier.com/es-es/services',
    'PT-PT': 'https://www.cartier.com/pt-pt/services',
    'PT-en': 'https://www.cartier.com/en-pt/services',
    'NL - NL': 'https://www.cartier.com/nl-nl/services',
    'EN - NL': 'https://www.cartier.com/en-nl/services',
    'BE - fr': 'https://www.cartier.com/fr-be/services',
    'Be - en': 'https://www.cartier.com/en-be/services',
    'LU - fr': 'https://www.cartier.com/fr-lu/services',
    'LU - en': 'https://www.cartier.com/en-lu/services',
    'CH-DE': 'https://www.cartier.com/de-ch/services',
    'CH-FR': 'https://www.cartier.com/fr-ch/services',
    'CH-IT': 'https://www.cartier.com/it-ch/services',
    US: 'https://www.cartier.com/en-us/services.html',
    'CA-en': 'https://www.cartier.com/en-ca/services.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/services.html',
    'AT-de': 'https://www.cartier.com/de-at/services',
    'AT-en': 'https://www.cartier.com/en-at/services',
    'MC-En': 'https://www.cartier.com/en-mc/services',
    'MC-fr': 'https://www.cartier.com/fr-mc/services',
    IE: 'https://www.cartier.com/en-ie/services',
    'KO-Kr': 'https://www.cartier.com/ko-kr/services.html',
    TW: 'https://www.cartier.com/zh-tw/services.html',
    'HK-Zh': 'https://www.cartier.hk/zh-hk/services.html',
    'HK-En': 'https://www.cartier.hk/en-hk/services.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/services.html',
    'SG-En': 'https://www.cartier.sg/en-sg/services.html',
    AU: 'https://www.cartier.com.au/en-au/services.html',
    'SA-En': 'https://www.cartier.com/en-sa/services.html',
    'AE-En': 'https://www.cartier.com/en-ae/services.html',
    'MY-En': 'https://www.cartier.com/en-my/services.html',
    'TH-En': 'https://www.cartier.com/en-th/services.html',
    'en-de': 'https://www.cartier.com/en-de/services',
    'en-es': 'https://www.cartier.com/en-es/services',
    'en-it': 'https://www.cartier.com/en-it/services',
    'en-ch': 'https://www.cartier.com/en-ch/services',
    'nl-be': 'https://www.cartier.com/nl-be/services',
    'sv-se': 'https://www.cartier.com/sv-se/services',
    'en-se': 'https://www.cartier.com/en-se/services',
    'en-dk': 'https://www.cartier.com/en-dk/services',
    'en-gr': 'https://www.cartier.com/en-gr/services',
    'en-cz': 'https://www.cartier.com/en-cz/services',
  },
  'Terms of use': {
    GB: 'https://www.cartier.com/en-gb/legal-and-privacy/terms-of-use',
    FR: 'https://www.cartier.com/fr-fr/legal-and-privacy/terms-of-use',
    DE: 'https://www.cartier.com/de-de/legal-and-privacy/terms-of-use',
    IT: 'https://www.cartier.com/it-it/legal-and-privacy/terms-of-use',
    ES: 'https://www.cartier.com/es-es/legal-and-privacy/terms-of-use',
    'PT-PT': 'https://www.cartier.com/pt-pt/legal-and-privacy/terms-of-use',
    'PT-en': 'https://www.cartier.com/en-pt/legal-and-privacy/terms-of-use',
    'NL - NL': 'https://www.cartier.com/nl-nl/legal-and-privacy/terms-of-use',
    'EN - NL': 'https://www.cartier.com/en-nl/legal-and-privacy/terms-of-use',
    'BE - fr': 'https://www.cartier.com/fr-be/legal-and-privacy/terms-of-use',
    'Be - en': 'https://www.cartier.com/en-be/legal-and-privacy/terms-of-use',
    'LU - fr': 'https://www.cartier.com/fr-lu/legal-and-privacy/terms-of-use',
    'LU - en': 'https://www.cartier.com/en-lu/legal-and-privacy/terms-of-use',
    'CH-DE': 'https://www.cartier.com/de-ch/legal-and-privacy/terms-of-use',
    'CH-FR': 'https://www.cartier.com/fr-ch/legal-and-privacy/terms-of-use',
    'CH-IT': 'https://www.cartier.com/it-ch/legal-and-privacy/terms-of-use',
    US: 'https://www.cartier.com/en-us/terms-of-use.html',
    'CA-en': 'https://www.cartier.com/en-ca/terms-of-use.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/terms-of-use.html',
    'AT-de': 'https://www.cartier.com/de-at/legal-and-privacy/terms-of-use',
    'AT-en': 'https://www.cartier.com/en-at/legal-and-privacy/terms-of-use',
    'MC-En': 'https://www.cartier.com/en-mc/legal-and-privacy/terms-of-use',
    'MC-fr': 'https://www.cartier.com/fr-mc/legal-and-privacy/terms-of-use',
    IE: 'https://www.cartier.com/en-ie/legal-and-privacy/terms-of-use',
    'KO-Kr': 'https://www.cartier.com/ko-kr/terms-of-use.html',
    TW: 'https://www.cartier.com/zh-tw/terms-of-use.html',
    'HK-Zh': 'https://www.cartier.hk/zh-hk/others/legal-area/terms-use.html',
    'HK-En': 'https://www.cartier.hk/en-hk/others/legal-area/terms-use.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/others/legal-area/terms-use.html',
    'SG-En': 'https://www.cartier.sg/en-sg/others/legal-area/terms-use.html',
    AU: 'https://www.cartier.com.au/en-au/others/legal-area/terms-use.html',
    'SA-En': 'https://www.cartier.com/en-sa/terms-of-use.html',
    'AE-En': 'https://www.cartier.com/en-ae/terms-of-use.html',
    'MY-En': 'https://www.cartier.com/en-my/terms-of-use.html',
    'TH-En': 'https://www.cartier.com/en-th/terms-of-use.html',
    'en-de': 'https://www.cartier.com/en-de/legal-and-privacy/terms-of-use',
    'en-es': 'https://www.cartier.com/en-es/legal-and-privacy/terms-of-use',
    'en-it': 'https://www.cartier.com/en-it/legal-and-privacy/terms-of-use',
    'en-ch': 'https://www.cartier.com/en-ch/legal-and-privacy/terms-of-use',
    'nl-be': 'https://www.cartier.com/nl-be/legal-and-privacy/terms-of-use',
    'sv-se': 'https://www.cartier.com/sv-se/legal-and-privacy/terms-of-use',
    'en-se': 'https://www.cartier.com/en-se/legal-and-privacy/terms-of-use',
    'en-dk': 'https://www.cartier.com/en-dk/legal-and-privacy/terms-of-use',
    'en-gr': 'https://www.cartier.com/en-gr/legal-and-privacy/terms-of-use',
    'en-cz': 'https://www.cartier.com/en-cz/legal-and-privacy/terms-of-use',
  },
  'Privacy policy': {
    GB: 'https://www.cartier.com/en-gb/legal-and-privacy/privacy-policy',
    FR: 'https://www.cartier.com/fr-fr/legal-and-privacy/privacy-policy',
    DE: 'https://www.cartier.com/de-de/legal-and-privacy/privacy-policy',
    IT: 'https://www.cartier.com/it-it/legal-and-privacy/privacy-policy',
    ES: 'https://www.cartier.com/es-es/legal-and-privacy/privacy-policy',
    'PT-PT': 'https://www.cartier.com/pt-pt/legal-and-privacy/privacy-policy',
    'PT-en': 'https://www.cartier.com/en-pt/legal-and-privacy/privacy-policy',
    'NL - NL': 'https://www.cartier.com/nl-nl/legal-and-privacy/privacy-policy',
    'EN - NL': 'https://www.cartier.com/en-nl/legal-and-privacy/privacy-policy',
    'BE - fr': 'https://www.cartier.com/fr-be/legal-and-privacy/privacy-policy',
    'Be - en': 'https://www.cartier.com/en-be/legal-and-privacy/privacy-policy',
    'LU - fr': 'https://www.cartier.com/fr-lu/legal-and-privacy/privacy-policy',
    'LU - en': 'https://www.cartier.com/en-lu/legal-and-privacy/privacy-policy',
    'CH-DE': 'https://www.cartier.com/de-ch/legal-and-privacy/privacy-policy',
    'CH-FR': 'https://www.cartier.com/fr-ch/legal-and-privacy/privacy-policy',
    'CH-IT': 'https://www.cartier.com/it-ch/legal-and-privacy/privacy-policy',
    US: 'https://www.cartier.com/en-us/resources/privacy-policy-template/privacy-policy.html',
    'CA-en': 'https://www.cartier.com/en-ca/resources/privacy-policy-template/privacy-policy.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/resources/privacy-policy-template/privacy-policy.html',
    'AT-de': 'https://www.cartier.com/de-at/legal-and-privacy/privacy-policy',
    'AT-en': 'https://www.cartier.com/en-at/legal-and-privacy/privacy-policy',
    'MC-En': 'https://www.cartier.com/en-mc/legal-and-privacy/privacy-policy',
    'MC-fr': 'https://www.cartier.com/fr-mc/legal-and-privacy/privacy-policy',
    IE: 'https://www.cartier.com/en-ie/legal-and-privacy/privacy-policy',
    'KO-Kr': 'https://www.cartier.com/ko-kr/resources/privacy-policy-template/privacy-policy.html',
    TW: 'https://www.cartier.com/zh-tw/resources/privacy-policy-template/privacy-policy.html',
    'HK-Zh': 'https://www.cartier.hk/zh-hk/others/legal-area/privacy-notice.html',
    'HK-En': 'https://www.cartier.hk/en-hk/others/legal-area/privacy-notice.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/others/legal-area/privacy-notice.html',
    'SG-En': 'https://www.cartier.sg/en-sg/others/legal-area/privacy-notice.html',
    AU: 'https://www.cartier.com.au/en-au/others/legal-area/privacy-notice.html',
    'SA-En': 'https://www.cartier.com/en-sa/resources/privacy-policy-template/privacy-policy.html',
    'AE-En': 'https://www.cartier.com/en-ae/resources/privacy-policy-template/privacy-policy.html',
    'MY-En': 'https://www.cartier.com/en-my/resources/privacy-policy-template/privacy-policy.html',
    'TH-En': 'https://www.cartier.com/en-th/resources/privacy-policy-template/privacy-policy.html',
    'en-de': 'https://www.cartier.com/en-de/legal-and-privacy/privacy-policy',
    'en-es': 'https://www.cartier.com/en-es/legal-and-privacy/privacy-policy',
    'en-it': 'https://www.cartier.com/en-it/legal-and-privacy/privacy-policy',
    'en-ch': 'https://www.cartier.com/en-ch/legal-and-privacy/privacy-policy',
    'nl-be': 'https://www.cartier.com/nl-be/legal-and-privacy/privacy-policy',
    'sv-se': 'https://www.cartier.com/sv-se/legal-and-privacy/privacy-policy',
    'en-se': 'https://www.cartier.com/en-se/legal-and-privacy/privacy-policy',
    'en-dk': 'https://www.cartier.com/en-dk/legal-and-privacy/privacy-policy',
    'en-gr': 'https://www.cartier.com/en-gr/legal-and-privacy/privacy-policy',
    'en-cz': 'https://www.cartier.com/en-cz/legal-and-privacy/privacy-policy',
  },
  'Cookie policy': {
    GB: 'https://www.cartier.com/en-gb/legal-and-privacy/cookie-policy',
    FR: 'https://www.cartier.com/fr-fr/legal-and-privacy/cookie-policy',
    DE: 'https://www.cartier.com/de-de/legal-and-privacy/cookie-policy',
    IT: 'https://www.cartier.com/it-it/legal-and-privacy/cookie-policy',
    ES: 'https://www.cartier.com/es-es/legal-and-privacy/cookie-policy',
    'PT-PT': 'https://www.cartier.com/pt-pt/legal-and-privacy/cookie-policy',
    'PT-en': 'https://www.cartier.com/en-pt/legal-and-privacy/cookie-policy',
    'NL - NL': 'https://www.cartier.com/nl-nl/legal-and-privacy/cookie-policy',
    'EN - NL': 'https://www.cartier.com/en-nl/legal-and-privacy/cookie-policy',
    'BE - fr': 'https://www.cartier.com/fr-be/legal-and-privacy/cookie-policy',
    'Be - en': 'https://www.cartier.com/en-be/legal-and-privacy/cookie-policy',
    'LU - fr': 'https://www.cartier.com/fr-lu/legal-and-privacy/cookie-policy',
    'LU - en': 'https://www.cartier.com/en-lu/legal-and-privacy/cookie-policy',
    'CH-DE': 'https://www.cartier.com/de-ch/legal-and-privacy/cookie-policy',
    'CH-FR': 'https://www.cartier.com/fr-ch/legal-and-privacy/cookie-policy',
    'CH-IT': 'https://www.cartier.com/it-ch/legal-and-privacy/cookie-policy',
    US: 'https://www.cartier.com/en-us/resources/privacy-policy-template/privacy-policy.html',
    'CA-en': 'https://www.cartier.com/en-ca/resources/privacy-policy-template/privacy-policy.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/resources/privacy-policy-template/privacy-policy.html',
    'AT-de': 'https://www.cartier.com/de-at/legal-and-privacy/cookie-policy',
    'AT-en': 'https://www.cartier.com/en-at/legal-and-privacy/cookie-policy',
    'MC-En': 'https://www.cartier.com/en-mc/legal-and-privacy/cookie-policy',
    'MC-fr': 'https://www.cartier.com/fr-mc/legal-and-privacy/cookie-policy',
    IE: 'https://www.cartier.com/en-ie/legal-and-privacy/cookie-policy',
    'KO-Kr': 'https://www.cartier.com/ko-kr/resources/privacy-policy-template/privacy-policy.html',
    TW: 'https://www.cartier.com/zh-tw/resources/privacy-policy-template/privacy-policy.html',
    'HK-Zh': 'https://www.cartier.hk/zh-hk/others/legal-area/privacy-notice.html',
    'HK-En': 'https://www.cartier.hk/en-hk/others/legal-area/privacy-notice.html',
    'SG-Zh': 'https://www.cartier.sg/zh-sg/others/legal-area/privacy-notice.html',
    'SG-En': 'https://www.cartier.sg/en-sg/others/legal-area/privacy-notice.html',
    AU: 'https://www.cartier.com.au/en-au/others/legal-area/privacy-notice.html',
    'SA-En': 'https://www.cartier.com/en-sa/resources/privacy-policy-template/privacy-policy.html',
    'AE-En': 'https://www.cartier.com/en-ae/resources/privacy-policy-template/privacy-policy.html',
    'MY-En': 'https://www.cartier.com/en-my/resources/privacy-policy-template/privacy-policy.html',
    'TH-En': 'https://www.cartier.com/en-th/resources/privacy-policy-template/privacy-policy.html',
    'en-de': 'https://www.cartier.com/en-de/legal-and-privacy/cookie-policy',
    'en-es': 'https://www.cartier.com/en-es/legal-and-privacy/cookie-policy',
    'en-it': 'https://www.cartier.com/en-it/legal-and-privacy/cookie-policy',
    'en-ch': 'https://www.cartier.com/en-ch/legal-and-privacy/cookie-policy',
    'nl-be': 'https://www.cartier.com/nl-be/legal-and-privacy/cookie-policy',
    'sv-se': 'https://www.cartier.com/sv-se/legal-and-privacy/cookie-policy',
    'en-se': 'https://www.cartier.com/en-se/legal-and-privacy/cookie-policy',
    'en-dk': 'https://www.cartier.com/en-dk/legal-and-privacy/cookie-policy',
    'en-gr': 'https://www.cartier.com/en-gr/legal-and-privacy/cookie-policy',
    'en-cz': 'https://www.cartier.com/en-cz/legal-and-privacy/cookie-policy',
  },
  'Sizing guide': {
    GB: 'https://www.cartier.com/en-gb/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    FR: 'https://www.cartier.com/fr-fr/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    DE: 'https://www.cartier.com/de-de/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    IT: 'https://www.cartier.com/it-it/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    ES: 'https://www.cartier.com/es-es/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'PT-PT': 'https://www.cartier.com/pt-pt/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'PT-en': 'https://www.cartier.com/en-pt/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'NL - NL': 'https://www.cartier.com/nl-nl/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'EN - NL': 'https://www.cartier.com/en-nl/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'BE - fr': 'https://www.cartier.com/fr-be/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'Be - en': 'https://www.cartier.com/en-be/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'LU - fr': 'https://www.cartier.com/fr-lu/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'LU - en': 'https://www.cartier.com/en-lu/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'CH-DE': 'https://www.cartier.com/de-ch/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'CH-FR': 'https://www.cartier.com/fr-ch/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'CH-IT': 'https://www.cartier.com/it-ch/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    US: 'https://www.cartier.com/en-us/jewelry-sizing-guides.html',
    'CA-en': 'https://www.cartier.com/en-ca/jewelry-sizing-guides.html',
    'CA-fr': 'https://www.cartier.com/fr-ca/jewelry-sizing-guides.html',
    'AT-de': 'https://www.cartier.com/de-at/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'AT-en': 'https://www.cartier.com/en-at/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'MC-En': 'https://www.cartier.com/en-mc/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'MC-fr': 'https://www.cartier.com/fr-mc/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    IE: 'https://www.cartier.com/en-ie/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'KO-Kr': 'https://www.cartier.com/ko-kr/jewelry-sizing-guides.html',
    TW: 'https://www.cartier.com/zh-tw/jewellery-sizing-guides.html',
    'HK-Zh':
      'https://www.cartier.hk/content/dam/cartier_dam/catalogue_assets/Cartier_Guide/rings/Sizing-guide_RINGS_210x297_HK_2019_v2.pdf',
    'HK-En':
      'https://www.cartier.hk/content/dam/cartier_dam/catalogue_assets/Cartier_Guide/rings/Sizing-guide_RINGS_210x297_UK_2019.pdf',
    'SG-Zh':
      'https://www.cartier.sg/content/dam/cartier_dam/catalogue_assets/Cartier_Guide/rings/Sizing-guide_RINGS_210x297_CHS_2019.pdf',
    'SG-En':
      'https://www.cartier.sg/content/dam/cartier_dam/catalogue_assets/Cartier_Guide/rings/Sizing-guide_RINGS_210x297_UK_2019.pdf',
    AU: 'https://www.cartier.com.au/content/dam/cartier_dam/catalogue_assets/Cartier_Guide/rings/Sizing-guide_RINGS_210x297_UK_2019.pdf',
    'SA-En': 'https://www.cartier.com/en-sa/jewellery-sizing-guides.html',
    'AE-En': 'https://www.cartier.com/en-ae/jewellery-sizing-guides.html',
    'MY-En': 'https://www.cartier.com/en-my/jewellery-sizing-guides.html',
    'TH-En': 'https://www.cartier.com/en-th/jewellery-sizing-guides.html',
    'en-de': 'https://www.cartier.com/en-de/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-es': 'https://www.cartier.com/en-es/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-it': 'https://www.cartier.com/en-it/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-ch': 'https://www.cartier.com/en-ch/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'nl-be': 'https://www.cartier.com/nl-be/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'sv-se': 'https://www.cartier.com/sv-se/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-se': 'https://www.cartier.com/en-se/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-dk': 'https://www.cartier.com/en-dk/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-gr': 'https://www.cartier.com/en-gr/services/request-service/care-adjust-repair/jewellery-sizing-guides',
    'en-cz': 'https://www.cartier.com/en-cz/services/request-service/care-adjust-repair/jewellery-sizing-guides',
  },
};
export const appToCSVLanguageCodesMap = {
  'en-gb': 'GB',
  'fr-fr': 'FR',
  'de-de': 'DE',
  'it-it': 'IT',
  'es-es': 'ES',
  'pt-pt': 'PT-PT',
  'en-pt': 'PT-en',
  'nl-nl': 'NL - NL',
  'en-nl': 'EN - NL',
  'fr-be': 'BE - fr',
  'en-be': 'Be - en',
  'fr-lu': 'LU - fr',
  'en-lu': 'LU - en',
  'de-ch': 'CH-DE',
  'fr-ch': 'CH-FR',
  'it-ch': 'CH-IT',
  'en-us': 'US',
  'en-ca': 'CA-en',
  'fr-ca': 'CA-fr',
  'de-at': 'AT-de',
  'en-at': 'AT-en',
  'en-mc': 'MC-En',
  'fr-mc': 'MC-fr',
  'en-ie': 'IE',
  'ko-kr': 'KO-Kr',
  'zh-tw': 'TW',
  'zh-hk': 'HK-Zh',
  'en-hk': 'HK-En',
  'zh-sg': 'SG-Zh',
  'en-sg': 'SG-En',
  'en-au': 'AU',
  'en-sa': 'SA-En',
  'en-ae': 'AE-En',
  'en-my': 'MY-En',
  'en-th': 'TH-En',
  'th-th': 'TH-Th',
  'en-de': 'en-de',
  'en-es': 'en-es',
  'en-it': 'en-it',
  'en-ch': 'en-ch',
  'nl-be': 'nl-be',
  'sv-se': 'sv-se',
  'en-se': 'en-se',
  'en-dk': 'en-dk',
  'en-gr': 'en-gr',
  'en-cz': 'en-cz',
};
export const appToCSVLinksMap = {
  mainCartier: 'cartier.com',
  contactUs: 'Contact us',
  help: 'Help',
  findBoutique: 'Find a boutique',
  services: 'Services',
  termsOfUse: 'Terms of use',
  privacyPolicy: 'Privacy policy',
  cookiePolicy: 'Cookie policy',
  sizingGuide: 'Sizing guide',
  storeLocator: 'Store locator',
};
